var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"9","md":"8"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"title"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("title." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.title'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) }),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.title'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('general.field_lang', { field: _vm.$t('labels.title'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},model:{value:(_vm.form.title[k]),callback:function ($$v) {_vm.$set(_vm.form.title, k, $$v)},expression:"form.title[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"seo_title"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("seo_title." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.seo_title'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) }),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.seo_title'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('general.field_lang', { field: _vm.$t('labels.seo_title'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},model:{value:(_vm.form.seo_title[k]),callback:function ($$v) {_vm.$set(_vm.form.seo_title, k, $$v)},expression:"form.seo_title[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"tags","name":_vm.$t('labels.keywords')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.keywords')}},[_c('b-form-tags',{attrs:{"input-id":"tags-pills","tag-variant":"primary","tag-pills":"","size":"md","separator":" ","placeholder":_vm.$t('labels.keywords')},model:{value:(_vm.form.keywords),callback:function ($$v) {_vm.$set(_vm.form, "keywords", $$v)},expression:"form.keywords"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"description"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("description." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.description'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) }),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.description'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('quill-editor',{attrs:{"options":_vm.snowOption},model:{value:(_vm.form.description[k]),callback:function ($$v) {_vm.$set(_vm.form.description, k, $$v)},expression:"form.description[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"seo_description"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("seo_description." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.seo_description'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.seo_description'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('b-form-textarea',{attrs:{"placeholder":_vm.$t('general.field_lang', { field: _vm.$t('labels.seo_description'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},model:{value:(_vm.form.seo_description[k]),callback:function ($$v) {_vm.$set(_vm.form.seo_description, k, $$v)},expression:"form.seo_description[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"short_description"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("short_description." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.short_description'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.short_description'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('b-form-textarea',{attrs:{"placeholder":_vm.$t('general.field_lang', { field: _vm.$t('labels.short_description'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},model:{value:(_vm.form.short_description[k]),callback:function ($$v) {_vm.$set(_vm.form.short_description, k, $$v)},expression:"form.short_description[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"3","md":"4"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"image","name":_vm.$t('labels.image'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImagePreview',{attrs:{"label":_vm.$t('labels.image')},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}})],1),_c('hr'),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"blog_category_id","name":_vm.$t('Blog Categories'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Blog Categories')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"title","options":_vm.blog_categories,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('Blog Categories')},model:{value:(_vm.form.blog_category_id),callback:function ($$v) {_vm.$set(_vm.form, "blog_category_id", $$v)},expression:"form.blog_category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('hr'),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"status","name":_vm.$t('status'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('status')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name","options":_vm.statuses,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('status')},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('labels.show_homepage'))+" ")]),_c('b-form-checkbox',{attrs:{"checked":"true","value":"true","unchecked-value":"false","name":"check-button","switch":""},model:{value:(_vm.form.show_homepage),callback:function ($$v) {_vm.$set(_vm.form, "show_homepage", $$v)},expression:"form.show_homepage"}})],1)])],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('labels.is_main'))+" ")]),_c('b-form-checkbox',{attrs:{"checked":"true","value":"true","unchecked-value":"false","name":"check-button","switch":""},model:{value:(_vm.form.is_main),callback:function ($$v) {_vm.$set(_vm.form, "is_main", $$v)},expression:"form.is_main"}})],1)])],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12 text-center"}},[_c('LoadingButton'),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('labels.reset'))+" ")])],1)],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }